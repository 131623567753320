import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-deadlock/definition.mjs";
import type { Steam } from "@/game-deadlock/models/steamAccount.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";
import isEmpty from "@/util/is-empty.mjs";
import isObject from "@/util/is-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsDeadlock() {
  const state = useSnapshot(readState);
  const deadlock = state.settings.loggedInAccounts[
    definition.shortName
  ] as Record<string, Steam>;
  const profiles = useMemo(
    () =>
      Object.entries(deadlock ?? {})
        .filter(
          ([, profile]) =>
            !(profile instanceof Error) &&
            isObject(profile) &&
            !isEmpty(profile),
        )
        .map(([steamId, profile]) => ({
          displayName: profile.name,
          key: steamId,
          avatar: profile.avatar,
        })),
    [deadlock],
  );
  return (
    <PageContainer>
      <Accounts
        profiles={profiles}
        gameShort={definition.shortName}
        autoDetectText={[
          "deadlock:settings.autoDetectProfile",
          "Hey! I can automatically detect your profile. Just open Deadlock and log into the account you want to connect.",
        ]}
        renderOtherActionItems={undefined}
        renderOtherItems={undefined}
      />
    </PageContainer>
  );
}
